@import 'mantine';
.dataCatalogueBlock {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, var(--mantine-color-gray-4), white);
  padding-top: rem(40);
  padding-bottom: rem(40);

  & > div {
    flex-basis: 100%;
  }

  @include larger-than($mantine-breakpoint-desktop-sm) {
    height: rem(648);
  }
}

.catalogueImagesWrapper {
  padding-bottom: rem(40);

  @include larger-than($mantine-breakpoint-desktop-sm) {
    padding: 0 var(--grid-col-padding);
  }
}

.serviceName {
  color: var(--mantine-color-gray-7);
}

.image {
  width: 100%;
  height: auto;
}
